export default {
  blogerName: 'JTA',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@estrategiaslotsoficial',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@canaljotinha/videos',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/canaldojotinha/',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/cabc054b5',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c90f174ad',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ca93b62ef',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c575f8fda',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c685990a6',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c06552bc5',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c08e5ded2',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c1f6bf440',
      gameTitle: 'Starda Queen (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>JTA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'JTA',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
